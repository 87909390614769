import { forwardRef } from 'react';
import { oneLine } from 'common-tags';

import type { ComponentPropsWithRef } from 'react';
import type { LinkProps } from './types';

import '@/lib/utils/forwardRef';

const LinkAbstract = <T extends React.ElementType = 'a'>(
  {
    as,
    type = 'primary',
    size = 'small',
    className = '',
    children,
    ...props
  }: LinkProps<T> & Omit<ComponentPropsWithRef<T>, keyof LinkProps<T>>,
  ref: React.Ref<HTMLAnchorElement>,
) => {
  const HTMLTag = as || 'a';

  return (
    <HTMLTag
      ref={ref}
      // prettier-ignore
      className={oneLine`
        u-actions pb-1 aria-disabled:pointer-events-none aria-disabled:text-primary-200 hover:cursor-pointer
        ${type === 'primary' ? 'text-primary-900  hover:text-primary-600' : ''}
        ${
          type === 'secondary'
            ? 'text-secondary-500 hover:text-secondary-600'
            : ''
        }
        ${
          type === 'alternative'
            ? 'text-typo-alternative hover:text-primary-900'
            : ''
        }
        ${type === 'current-color' ? 'text-current hover:text-current' : ''}
        ${
          type === 'primary' || type === 'alternative'
            ? 'underline underline-offset-4'
            : ''
        }
        ${size === 'body' ? 'u-body u-body--xs normal-case' : ''}
        ${size === 'small' ? 'u-actions u-actions--link' : ''}
        ${size === 'large' ? 'u-actions u-actions--link-large' : ''}
        ${className}
      `}
      {...props}
    >
      {children}
    </HTMLTag>
  );
};

export const Link = forwardRef(LinkAbstract);
