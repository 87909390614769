import type { ElementType } from 'react';
import type { TypeTextProps } from './types';

export const Text = <T extends ElementType = 'p'>({
  as,
  children,
  ...props
}: TypeTextProps<T>) => {
  const HTMLTag = as || 'p';

  return <HTMLTag {...props}>{children}</HTMLTag>;
};
