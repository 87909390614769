import type {
  TypeAlignContent,
  TypeBackground,
  TypeStyle,
} from '@/components/types';
import type { TypeContainerGraphQL } from '@/lib/graphql/types/common/container';
import type { TypeSrcImageGraphQL } from '../../types';

interface TypePartialContainer
  extends TypeBackground,
    TypeAlignContent,
    TypeStyle {}

export const containerTransformer = (
  container: TypeContainerGraphQL,
): TypePartialContainer => {
  const baseImage = container?.imagenBase?.sourceUrl as string;
  const layoutImages: {
    src: string;
    media: string;
    width: number;
    height: number;
  }[] =
    container?.srcset
      ?.filter((i) => Boolean(i.imagen))
      .map((image: TypeSrcImageGraphQL) => ({
        media: image.anchura,
        src: image.imagen?.sourceUrl,
        width: image.imagen.mediaDetails.width as number,
        height: image.imagen.mediaDetails.height as number,
      })) ?? [];

  return {
    ...(container?.imagenBase
      ? container?.imagenBase?.sourceUrl
        ? {
            backgroundImage: {
              src: baseImage,
              srcset: layoutImages,
              alt: container?.imagenBase?.altText || 'Imagen de fondo',
              height: container.imagenBase.mediaDetails.height,
              width: container.imagenBase.mediaDetails.width,
            },
          }
        : {
            backgroundImage: baseImage,
          }
      : {}),
    ...(container?.colorDeFondo
      ? { style: { backgroundColor: container?.colorDeFondo } }
      : {}),
    ...(container?.overlay ? { overlay: container?.overlay / 100 } : {}),
    ...(container?.justify
      ? {
          justify: container?.justify.reduce(
            (
              previousValue: string,
              currentValue: {
                mediaQuery: string;
                justify: string;
              },
            ) =>
              `${previousValue} ${
                currentValue.mediaQuery === 'none'
                  ? currentValue.justify
                  : `${currentValue.mediaQuery}:${currentValue.justify}`
              }`,
            '',
          ),
        }
      : {}),
    ...(container?.align
      ? {
          align: container?.align.reduce(
            (
              previousValue: string,
              currentValue: {
                mediaQuery: string;
                align: string;
              },
            ) =>
              `${previousValue} ${
                currentValue.mediaQuery === 'none'
                  ? currentValue.align
                  : `${currentValue.mediaQuery}:${currentValue.align}`
              }`,
            '',
          ),
        }
      : {}),
    ...(container?.textAlign
      ? {
          textAlign: container?.textAlign.reduce(
            (
              previousValue: string,
              currentValue: {
                mediaQuery: string;
                textAlign: string;
              },
            ) =>
              `${previousValue} ${
                currentValue.mediaQuery === 'none'
                  ? currentValue.textAlign
                  : `${currentValue.mediaQuery}:${currentValue.textAlign}`
              }`,
            '',
          ),
        }
      : {}),
  };
};
